import CartEmpty from './CartEmpty';
import CartItem from './CartItem';
import CartTotals from './CartTotals';
import FocusTrap from 'focus-trap-react';

import cn from 'classnames';
import { createPortal } from 'preact/compat';
import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const MiniCartDrawerEl = document.getElementById('preact-mini-cart-drawer');

const transitionClasses = {
  delay: 'delay-300',
  duration: 'duration-300',
};

const MiniCart = () => {
  const { cart, isMiniCartOpen, toggleMiniCart, miniCartData } =
    useCartContext();

  const {
    contentForAdditionalButtons,
    additionalCheckoutButtons,
    installments,
  } = miniCartData.settings;

  let percentage = 0;

  const thresholdInCents = miniCartData.settings.freeShippingThreshold * 100;
  const difference = thresholdInCents - cart.total_price;

  if (difference > 0) {
    percentage = (cart.total_price * 100) / thresholdInCents;
  } else {
    percentage = 100;
  }

  return createPortal(
    <FocusTrap active={isMiniCartOpen}>
      <div
        class={cn(
          'fixed inset-0',
          isMiniCartOpen
            ? 'opacity-1 visible'
            : `invisible opacity-0 ${transitionClasses.delay}`,
        )}
      >
        <div
          onClick={() => toggleMiniCart(false)}
          class={cn(
            `absolute inset-0 bg-grey-1 transition-opacity ${transitionClasses.duration}`,
            isMiniCartOpen ? 'opacity-50' : 'opacity-0',
          )}
        />
        <div
          class={cn(
            `absolute bottom-0 right-0 top-0 z-50 flex w-full flex-col bg-grey-2 pb-4 transition-transform sm:max-w-md ${transitionClasses.duration}`,
            isMiniCartOpen ? 'translate-x-0' : 'translate-x-full',
          )}
        >
          <div class="flex justify-between px-4 py-3">
            <p class="type-heading-5">
              {getTranslation('mini_cart.your_cart', {
                count: cart.item_count,
              })}
            </p>
            <button onClick={() => toggleMiniCart(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 40 40"
                className="h-7 w-7"
              >
                <g clip-path="url(#a)">
                  <path
                    d="M9.4 9.6 20 20.2m0 0L9.4 30.8M20 20.2l10.6 10.6M20 20.2 30.6 9.6"
                    stroke="currentColor"
                    stroke-width="2"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h40v40H0z" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>

          {miniCartData?.settings?.freeShippingThreshold > 0 && (
            <div class="relative flex h-16 items-center justify-center bg-primary-2/60">
              <div class="type-body-small relative z-10">
                {percentage === 100
                  ? getTranslation('mini_cart.freeShippingCompleteMessage')
                  : getTranslation('mini_cart.freeShippingMessage', {
                      amount: formatMoney(difference, theme.moneyFormat),
                    })}
              </div>
              <div
                class="absolute inset-y-0 left-0 bg-secondary-1/20"
                style={{ width: `${percentage.toFixed(2)}%` }}
              ></div>
            </div>
          )}
          <div
            class="flex h-full flex-col overflow-auto"
            data-cart-items
            data-scroll-lock-scrollable
          >
            {/* <div class="mt-auto">
              <CartCrossSell />
            </div> */}
            {cart.items && cart.items.length > 0 ? (
              cart.items.map(item => <CartItem item={item} key={item.key} />)
            ) : (
              <CartEmpty />
            )}
          </div>
          {cart.item_count > 0 && (
            <>
              <hr class="mt-auto border-primary-2/60" />
              <div class="px-4">
                <CartTotals cart={{ ...cart }} />

                {installments && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: installments,
                    }}
                  ></div>
                )}
                <div class="mt-4 flex items-center justify-between">
                  <form
                    action={`${Shopify.routes.root}cart`}
                    method="POST"
                    class="w-full"
                  >
                    <button
                      type="submit"
                      name="checkout"
                      class="button button--primary button--block"
                    >
                      {getTranslation('mini_cart.checkout')}
                    </button>

                    {additionalCheckoutButtons && (
                      <div
                        class="additional-checkout-buttons additional-checkout-buttons--vertical"
                        dangerouslySetInnerHTML={{
                          __html: contentForAdditionalButtons,
                        }}
                      ></div>
                    )}
                  </form>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </FocusTrap>,
    MiniCartDrawerEl,
  );
};

export default MiniCart;
