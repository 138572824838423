import CartCrossSellProduct from './CartCrossSellProduct';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { useEffect, useState } from 'preact/hooks';

const CartCrossSell = () => {
  const { cart, isMiniCartOpen, miniCartData } = useCartContext();
  const [crossSellItems, setCrossSellItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to remove objects with duplicate IDs
  const removeDuplicates = arr => {
    const uniqueIds = [];

    return arr.filter(item => {
      if (uniqueIds.indexOf(item.id) === -1) {
        uniqueIds.push(item.id);
        return true;
      }
      return false;
    });
  };

  useEffect(() => {
    setCurrentIndex(0);
  }, [crossSellItems]);

  useEffect(() => {
    if (isMiniCartOpen && cart && cart.items.length > 0) {
      setCrossSellItems([]);
      cart.items.forEach(item => {
        fetch(
          `${Shopify.routes.root}recommendations/products.json?product_id=${
            item.product_id
          }&limit=${miniCartData.settings.limit || 3}&intent=related`,
        )
          .then(response => {
            return response.json();
          })
          .then(({ products }) => {
            if (products.length > 0) {
              setCrossSellItems(prev =>
                removeDuplicates([...prev, ...products]),
              );
            }
          });
      });
    }
  }, [cart.items, isMiniCartOpen]);

  return crossSellItems.length > 0 ? (
    <div class="relative">
      <h5 class="type-heading-5 bg-primary-2/60 px-4 py-3">
        {getTranslation('mini_cart.add_on')}
      </h5>

      <Splide
        className="static"
        hasTrack={false}
        onMove={(_, index) => setCurrentIndex(index)}
        options={{ rewind: true, pagination: false }}
      >
        <div className="splide__arrows absolute right-4 top-4 flex gap-2">
          <button className="splide__arrow splide__arrow--prev">
            <svg class="h-3" viewBox="0 0 11 17" fill="none">
              <path
                stroke="currentColor"
                strokeWidth={2}
                d="M9.8 16 1.7 8.4 9.8 1"
              />
            </svg>
          </button>
          <span class="type-heading-5">
            {currentIndex + 1}/{crossSellItems.length}
          </span>
          <button className="splide__arrow splide__arrow--next">
            <svg viewBox="0 0 11 17" fill="none" class="h-3">
              <path
                stroke="currentColor"
                strokeWidth={2}
                d="m1.2 1.2 8.1 7.5-8.1 7.5"
              />
            </svg>
          </button>
        </div>
        <SplideTrack>
          {crossSellItems.map(item => (
            <SplideSlide key={item.product_id}>
              <CartCrossSellProduct product={item} />
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
    </div>
  ) : null;
};

export default CartCrossSell;
