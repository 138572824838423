import cn from 'classnames';
import { h } from 'preact';
import { useState } from 'preact/hooks';

const CartSwatches = ({ swatches, product, setCurrentProductByIndex }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div class="flex flex-wrap gap-2">
      {swatches.map((swatch, index) => {
        const image = swatch?.swatchImage?.reference?.image
          ? swatch.swatchImage.reference.image
          : swatch.featuredImage;

        return (
          <button
            key={swatch.id}
            onClick={() => setCurrentProductByIndex(index)}
            className={cn(
              'relative flex cursor-pointer items-center justify-center rounded-full',
              { hidden: index > 2 && !showMore },
              { 'active-swatch': swatch.handle === product.handle },
            )}
            data-swatch-link
          >
            <img
              src={image.url}
              className="aspect-square w-7 rounded-full border border-grey-3/5 object-contain"
            />
          </button>
        );
      })}
      <button
        onClick={() => setShowMore(prev => !prev)}
        className={cn(
          'type-body-small type-bold ml-1 flex cursor-pointer items-center justify-center hover:underline',
          { hidden: swatches.length <= 3 || showMore },
        )}
      >
        +<span>{swatches.length - 3}</span>
      </button>
    </div>
  );
};

export default CartSwatches;
