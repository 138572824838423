import Search from '../components/search/Search';
import Splide from '@splidejs/splide';
import scrollLock from 'scroll-lock';
import { pixelBreakpoints, unitlessBreakpoints } from '../helpers';

const selectors = {
  headerNavigation: '[data-header-navigation]',
  mobileNavigation: '[data-header-mobile-navigation]',
  headerNavDetails: '[data-header-nav-details]',
  headerMegaDetails: '[data-header-mega-details]',
  megaBackdrop: '[data-mega-backdrop]',
  megaBackButton: '[data-submenu-back]',
  navHamburger: '[data-nav-hamburger]',
  navClose: '[data-nav-close]',
  announcementBar: '[data-announcement-bar]',
  mobileImageBlocks: '[data-mobile-image-blocks]',
  megaContent: '[data-mega-content]',
};

export default class MainHeader {
  constructor(section) {
    this.section = section;

    const { settings } = section.data;

    if (settings.enable_fixed_header) {
      this.section.el.className += ' sticky top-0 z-50 bg-grey-2';
    }

    new Search(section);

    this.initNavigation();
    this.initMobileNavigation();
    this.initMobileImageBlocks();

    // Add this as a class property to track state
    this.isScrollLocked = false;
  }

  initMobileImageBlocks = () => {
    const mobileImageBlocks = this.section.el.querySelector(
      selectors.mobileImageBlocks,
    );
    if (mobileImageBlocks) {
      const mobileImageBlocksCarousel = new Splide(mobileImageBlocks, {
        perPage: 1,
        pagination: false,
        arrows: false,
        gap: '1rem',
        mediaQuery: 'min',
        padding: {
          right: '30%',
        },
        breakpoints: {
          420: {
            perPage: 2,
            padding: {
              right: '10%',
            },
          },
          [unitlessBreakpoints.sm]: {
            perPage: 2,
            padding: {
              right: '25%',
            },
          },
          [unitlessBreakpoints.md]: {
            perPage: 3,
            padding: {
              right: '10%',
            },
          },
          [unitlessBreakpoints.lg]: {
            destroy: true,
          },
        },
      });

      mobileImageBlocksCarousel.mount();
    }
  };

  initNavigation = () => {
    this.initMegaDetailsHover();
    this.initMegaDetailsToggle();
  };

  initMegaDetailsToggle = () => {
    const megaDetails = this.section.el.querySelectorAll(
      selectors.headerMegaDetails,
    );
    // Replace the mega menu event listeners
    megaDetails.forEach(link => {
      link.addEventListener('toggle', () => {
        clearTimeout(this.scrollTimeout);

        this.scrollTimeout = setTimeout(() => {
          const anyMegaMenuOpen = Array.from(megaDetails).some(
            detail => detail.open,
          );

          if (anyMegaMenuOpen && !this.isScrollLocked) {
            scrollLock.disablePageScroll();
            this.isScrollLocked = true;
          } else if (!anyMegaMenuOpen && this.isScrollLocked) {
            scrollLock.enablePageScroll();
            this.isScrollLocked = false;
          }
        }, 50); // Small delay to handle rapid toggles
      });
    });
  };

  initMegaDetailsHover = () => {
    const megaDetails = this.section.el.querySelectorAll(
      selectors.headerMegaDetails,
    );

    megaDetails.forEach(details => {
      const summary = details.querySelector('summary');
      const megaContent = details.querySelector(selectors.megaContent);

      summary.addEventListener('mouseenter', () => {
        if (window.matchMedia(`(min-width: ${pixelBreakpoints.lg})`).matches) {
          details.setAttribute('open', '');
        }
      });

      details.addEventListener('mouseleave', () => {
        if (window.matchMedia(`(min-width: ${pixelBreakpoints.lg})`).matches) {
          details.removeAttribute('open');
        }
      });

      megaContent.addEventListener('mouseleave', () => {
        if (window.matchMedia(`(min-width: ${pixelBreakpoints.lg})`).matches) {
          details.removeAttribute('open');
        }
      });
    });
  };

  initMobileNavigation = () => {
    const mobileImageBlocks = this.section.el.querySelector(
      selectors.mobileImageBlocks,
    );
    const linkDetails = this.section.el.querySelectorAll(
      selectors.headerNavDetails,
    );
    const megaDetails = this.section.el.querySelectorAll(
      selectors.headerMegaDetails,
    );
    const megaBackButtons = this.section.el.querySelectorAll(
      selectors.megaBackButton,
    );

    this.openMobileNav();
    this.closeMobileNav();

    // Set subnav heights
    [...linkDetails, ...megaDetails].forEach(link => {
      link.addEventListener('click', () => {
        if (!window.matchMedia(`(min-width: ${pixelBreakpoints.lg})`).matches) {
          const megaBackdrop = link.querySelector(selectors.megaBackdrop);
          const totalHeaderHeight = this.getHeaderHeight();
          megaBackdrop.style.height = `${
            window.innerHeight - totalHeaderHeight
          }px`;
        }
      });

      link.addEventListener('toggle', () => {
        if (!window.matchMedia(`(min-width: ${pixelBreakpoints.lg})`).matches) {
          if (link.hasAttribute('open')) {
            mobileImageBlocks.classList.add('hidden');
          } else {
            mobileImageBlocks.classList.remove('hidden');
          }
        }
      });
    });

    // Set subnav heights
    megaBackButtons.forEach(button => {
      button.addEventListener('click', () => {
        const parentDetail = button.closest('details');
        parentDetail.removeAttribute('open');
      });
    });
  };

  openMobileNav = () => {
    const headerNavigation = this.section.el.querySelector(
      selectors.mobileNavigation,
    );
    const navHamburger = this.section.el.querySelector(selectors.navHamburger);
    const navClose = this.section.el.querySelector(selectors.navClose);
    navHamburger.addEventListener('click', () => {
      const totalHeaderHeight = this.getHeaderHeight();
      headerNavigation.style.top = `${totalHeaderHeight - 1}px`;
      navHamburger.classList.add('hidden');
      navClose.classList.remove('hidden');
      headerNavigation.classList.remove('hidden');
      headerNavigation.classList.add('flex');
      scrollLock.disablePageScroll();
    });
  };

  closeMobileNav = () => {
    const headerNavigation = this.section.el.querySelector(
      selectors.mobileNavigation,
    );
    const navHamburger = this.section.el.querySelector(selectors.navHamburger);
    const navClose = this.section.el.querySelector(selectors.navClose);
    navClose.addEventListener('click', () => {
      navHamburger.classList.remove('hidden');
      navClose.classList.add('hidden');
      headerNavigation.classList.add('hidden');
      headerNavigation.classList.remove('flex');
      scrollLock.enablePageScroll();
    });
  };

  getHeaderHeight = () => {
    const announcementBar = document.querySelector(selectors.announcementBar);
    const headerHeight = this.section.el.offsetHeight;
    let announcementBarHeight = 0;
    if (announcementBar) {
      const announcementElementHeight = announcementBar.offsetHeight;
      if (document.documentElement.scrollTop < announcementElementHeight) {
        announcementBarHeight = announcementBar.offsetHeight;
      }
    }
    return headerHeight + announcementBarHeight;
  };
}
