import * as cartApi from '../../helpers/cartApi';
import JSON5 from 'json5';
import Modal from '../../components/Modal';
import Quantity from '../Quantity';
import { deepMerge } from '../../helpers';
import quickShop from './ProductQuickShop';
import { updateReactCart } from '../../preact/helpers/utilities';

import {
  ProductForm as ThemeProductForm,
  getUrlWithVariant,
} from '@shopify/theme-product-form';

const selectors = {
  form: '[data-product-form]',
  addToCart: '[data-add-to-cart]',
  quantityInput: '[data-quantity-input]',
  productSelect: '[data-product-select]',
  radioButtons: '[name="options[Size]"]',
  notifyContent: '[data-notify-modal-content]',
  notifyOpenButton: '[data-open-notify-modal]',
  notifySubmit: '[data-notify-submit]',
  notifyContinue: '[data-notify-continue]',
  notifySubmittedDescription: '[data-notify-submitted-description]',
  notifyContainer: '[data-notify-container]',
};

/**
 * Configuration of the PDP add to cart form
 *
 * @export
 * @class ProductForm
 */
export default class ProductForm {
  /**
   * Creates an instance of ProductForm.
   * @param {HTMLElement} productContainer The container of this product
   * @param {Object} config Settings object
   */
  constructor(productContainer, config = {}) {
    this.el = productContainer;
    this.formElement = this.el.querySelector(selectors.form);
    this.quantityInput = this.el.querySelector(selectors.quantityInput);
    this.modal = new Modal({ size: 'small' });
    this.variantInventory = JSON5.parse(
      productContainer.dataset.variantInventory,
    );
    this.config = {
      isQuickShop: false,
      onVariantChange: () => {},
      ...pixelThemeSettings.product,
      ...config,
    };

    fetch(
      `${Shopify.routes.root}products/${this.formElement.dataset.productHandle}.js`,
    )
      .then(response => {
        return response.json();
      })
      .then(productJSON => {
        this.product = productJSON;
        this.themeProductForm = new ThemeProductForm(
          this.formElement,
          productJSON,
          {
            onOptionChange: this._onOptionChange,
            onFormSubmit: this._onFormSubmit,
          },
        );
        this.quantity = new Quantity(this.el);

        if (
          this.product.options.length > 1 &&
          this.config.removeInvalidVariants
        ) {
          this.validVariantObject = this._createValidVariantObject();
          this._removeInvalidVariants();
        }
        if (
          this.product.options.length > 1 &&
          this.config.disableUnavailableVariants
        ) {
          this.availableVariantObject = this._createAvailableVariantObject();
          this._removeUnavailableVariants();
        }

        /**
         * On initialization, set source of truth
         */
        const variantFromUrl = this._getVariantFromUrl();
        this._updateHiddenSelect(selectors.productSelect, variantFromUrl);

        this._initNotifyModal();
      });
  }

  /**
   * Opens notify modal.
   */
  _initNotifyModal = () => {
    const notifyOpenButton = this.el.querySelector(selectors.notifyOpenButton);
    if (notifyOpenButton) {
      notifyOpenButton.addEventListener('click', this._openNotifyModal);
    }

    const notifySubmit = this.el.querySelector(selectors.notifySubmit);
    if (notifySubmit) {
      notifySubmit.addEventListener('click', this._submitNotifyModal);
    }

    const notifyContinue = this.el.querySelector(selectors.notifyContinue);
    if (notifyContinue) {
      notifyContinue.addEventListener('click', this._continueNotifyModal);
    }
  };

  /**
   * Opens notify modal.
   */
  _openNotifyModal = () => {
    const modal = this.el.querySelector(selectors.notifyContent);
    this.modal.open(`#${modal.getAttribute('id')}`);
  };

  /**
   * Submits notify modal.
   *
   * @param {object} container - Container of form to be opened in the drawer.
   */
  _submitNotifyModal = e => {
    e.preventDefault();
    const email = e.target.parentElement.querySelector(
      'input[name=notify-email]',
    );
    const phone = e.target.parentElement.querySelector(
      'input[name=notify-phone]',
    );
    let variant = this._getVariantFromUrl();
    if (!variant) {
      variant = this.themeProductForm.variant();
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email.value);

    if (!isValidEmail) {
      // Handle invalid email
      return;
    }

    smsBumpBackInStock
      .subscribe({
        phone: phone.value,
        email: email.value,
        country: 'US',
        timezone: '',
        variant: {
          id: variant.id,
          title: variant.title,
          sku: variant.sku,
        },
        product: {
          id: this.product.id,
          title: this.product.title,
        },
      })
      .then(res => {
        if (res.status !== 'error') {
          const container = document.querySelector(selectors.notifyContainer);
          container.classList.add('submitted');
          const submittedDescription = container.querySelector(
            selectors.notifySubmittedDescription,
          );
          submittedDescription.innerHTML = `We will email ${email.value} when the item becomes available.`;
        } else {
          alert(res.message);
        }
      });
  };

  /**
   * Closes the modal and removes the submitted state on the modal.
   */
  _continueNotifyModal = () => {
    const container = document.querySelector(selectors.notifyContainer);
    container.classList.remove('submitted');
    this.modal.close();
  };

  _getVariantFromUrl = () => {
    const variantId = new URLSearchParams(window.location.search).get(
      'variant',
    );

    if (variantId) {
      return this.product.variants.find(variant => variant.id === +variantId);
    }
    return null;
  };

  /**
   * Called any time an option is changed
   */
  _onOptionChange = () => {
    /**
     * On option change, update source of truth
     */

    if (this.product.options.length > 1 && this.config.removeInvalidVariants) {
      this._removeInvalidVariants();
    }
    if (
      this.product.options.length > 1 &&
      this.config.disableUnavailableVariants
    ) {
      this._removeUnavailableVariants();
    }
    this._updateHiddenSelect(selectors.productSelect);
  };

  /**
   * This function is called whenever the user submits the form
   *
   * @param {*} event
   */
  _onFormSubmit = event => {
    event.preventDefault();
    const button = this.el.querySelector(selectors.addToCart);

    if (button.dataset.selectSize) {
      const option2Span = document.querySelector(
        '[data-current-option="option2"]',
      );

      option2Span.innerHTML =
        '<span class="text-error type-body-small italic">Please select a size</span>';
    } else {
      cartApi
        .add(event.target)
        .then(() => {
          if (this.config.isQuickShop) {
            quickShop.close();
          }
          updateReactCart(true);
        })
        .catch(response => this._handleCartApiError(response));
    }
  };

  /**
   * Updates the dom after a variant has changed
   */
  _updateVariantState = variant => {
    if (variant === null) {
      this._disableAddToCartState();
      return;
    }

    if (!this.config.isQuickShop && !this.config.isFeaturedProduct) {
      this._updateUrl(variant);
    }
    this._updateQuantityVariant(variant);
    this.config.onVariantChange(variant);

    if (!variant.available) {
      this._soldOutAddToCartState();
    } else {
      this._enableAddToCartState();
    }

    this._updateCheckboxes(variant);
  };

  /**
   * Generic error handler for cart api
   * Example: 422 response when trying to add a product whose total stock is already in cart
   *
   * @param {Object} response The response from Shopify
   */
  _handleCartApiError = response => {
    const { error } = response;
    // eslint-disable-next-line no-console
    console.error(error);
  };

  /**
   * Update the url with the selected variant's ID
   *
   * @param {Object} variant
   */
  _updateUrl(variant) {
    if (variant) {
      const url = getUrlWithVariant(window.location.href, variant.id);
      window.history.replaceState({ path: url }, '', url);
    }
  }

  /**
   * Update the quantity input with the selected variant's ID and max quantity
   *
   * @param {Object} variant
   */
  _updateQuantityVariant(variant) {
    if (variant && this.quantityInput) {
      this.quantityInput.dataset.variantId = variant.id;
      let stock = 0;
      if (variant.inventory_management === null) {
        stock = 9999;
      } else {
        stock = this.variantInventory[variant.id];
      }
      this.quantityInput.max = stock;
    }
  }

  /**
   * disable button state and text
   */
  _soldOutAddToCartState = () => {
    const button = this.el.querySelector(selectors.addToCart);
    const notifyButton = this.el.querySelector(selectors.notifyOpenButton);

    button.classList.add('hidden');
    notifyButton.classList.remove('hidden');
  };

  /**
   * disable button state and text
   */
  _disableAddToCartState = () => {
    const button = this.el.querySelector(selectors.addToCart);

    button.dataset.selectSize = 'true';
  };

  /**
   * enable button state and text
   */
  _enableAddToCartState = () => {
    const button = this.el.querySelector(selectors.addToCart);
    const notifyButton = this.el.querySelector(selectors.notifyOpenButton);

    button.removeAttribute('disabled');
    button.classList.remove('hidden');
    notifyButton.classList.add('hidden');
    delete button.dataset.selectSize;
  };

  /**
   * Map over all variants and create a
   * nested object to define them
   *
   * @param {Boolean} mapAllVariants if true map over all variants, if false only map available variants
   */
  _createVariantObject = mapAllVariants => {
    let obj = {};
    this.product.variants.forEach(variant => {
      if (variant.available || mapAllVariants) {
        const optionsCopy = [...variant.options];
        const newObj = optionsCopy
          .reverse()
          .reduce((res, key) => ({ [key]: res }), {});
        obj = deepMerge(obj, newObj);
      }
    });

    return obj;
  };

  /**
   * Create object to define invalid variants
   */
  _createValidVariantObject = () => {
    return this._createVariantObject(true);
  };

  /**
   * Create object to define available variants
   */
  _createAvailableVariantObject = () => {
    return this._createVariantObject(false);
  };

  /**
   * Mutate variant object to format of variant.options
   * @param {Object} variantObject Object to map over
   * @returns {Array} options array with only values listed in the variantObject
   */
  _mapVariantObject = variantObject => {
    const currentOptions = this.themeProductForm.options();
    let variantObjectCopy = { ...variantObject };

    const newOptions = this.product.options.map((option, index) => {
      const values = Object.keys(variantObjectCopy);

      if (variantObjectCopy[currentOptions[index]?.value]) {
        variantObjectCopy = variantObjectCopy[currentOptions[index].value];
      } else {
        variantObjectCopy =
          variantObjectCopy[Object.keys(variantObjectCopy)[0]];
      }

      return {
        ...option,
        values,
      };
    });

    return newOptions;
  };

  /**
   * Remove options that are not valid variants and pass
   * them to function to update DOM
   */
  _removeInvalidVariants = () => {
    const newOptions = this._mapVariantObject(this.validVariantObject);

    if (this.config.variantDisplayType === 'radio') {
      this._removeInvalidRadioButtons(newOptions);
    } else {
      this._removeInvalidSelectOptions(newOptions);
    }
  };

  /**
   * Update Select options with only the options of valid variants
   * @param {Array} newOptions array of options to display
   */
  _removeInvalidSelectOptions = newOptions => {
    const currentOptions = this.themeProductForm.options();

    newOptions.forEach((option, index) => {
      const select = document.getElementById(
        `${this.product.handle}-option-${option.position}`,
      );
      select.options.length = 0;
      option.values.forEach(value => {
        const isSelected = currentOptions[index].value === value;
        select.appendChild(new Option(value, value, isSelected, isSelected));
      });
    });
  };

  /**
   * Map over radio buttons and apply mutations to them if they are in the new options
   * @param {Array} newOptions options that are valid
   * @param {Function} optionNotIncluded Function to run if option is not included in the new options
   * @param {Function} optionIncluded Function to run if the option is included in the new options
   */
  _mutateRadioButtons = (newOptions, optionNotIncluded, optionIncluded) => {
    this.product.options.forEach((option, index) => {
      const radioGroup = document.getElementById(
        `${this.product.handle}-option-${option.position}`,
      );

      option.values.forEach(value => {
        const newOptionValues = newOptions[index].values;
        const firstAvailableInput = radioGroup.querySelector(
          `input[value="${newOptionValues[0]}"]`,
        );
        const input = radioGroup.querySelector(`input[value="${value}"]`);
        const inputParent = input.parentElement;

        if (!newOptionValues.includes(value)) {
          optionNotIncluded(inputParent, input);
          if (input.checked) {
            if (firstAvailableInput) {
              firstAvailableInput.checked = 'checked';
            }
            const event = document.createEvent('HTMLEvents');
            event.initEvent('change', true, false);
            input.dispatchEvent(event);
          }
        } else {
          optionIncluded(inputParent, input);
        }
      });
    });
  };

  /**
   * Remove radio buttons from the page if they are invalid variants
   * @param {Array} newOptions array of valid variants
   */
  _removeInvalidRadioButtons = newOptions => {
    const optionNotIncluded = inputParent => {
      inputParent.style.display = 'none';
    };

    const optionIncluded = inputParent => {
      inputParent.style.display = null;
    };

    this._mutateRadioButtons(newOptions, optionNotIncluded, optionIncluded);
  };

  /**
   * Remove options that are not available variants and pass
   * them to function to update DOM
   */
  _removeUnavailableVariants = () => {
    const newOptions = this._mapVariantObject(this.availableVariantObject);

    if (this.config.variantDisplayType === 'radio') {
      this._disableRadioButtons(newOptions);
    } else {
      this._disableSelectOptions(newOptions);
    }
  };

  /**
   * Disable Select options when they are not in the list of available variants in newOptions
   * @param {Array} newOptions array of options that are available
   */
  _disableSelectOptions = newOptions => {
    newOptions.forEach(option => {
      const select = document.getElementById(
        `${this.product.handle}-option-${option.position}`,
      );
      // Check if currently selected value will be disabled
      let selectionLost = !option.values.includes(
        select.options[select.selectedIndex].value,
      );

      // Loop over all options and disable the ones not in the newOptions
      Array.from(select.options).forEach(optionEl => {
        if (!option.values.includes(optionEl.value)) {
          optionEl.disabled = true;
        } else {
          // Select the first available option if currently selected gets disabled
          if (selectionLost) {
            optionEl.selected = true;
            selectionLost = false;
          }
          optionEl.disabled = false;
        }
      });
    });
  };

  /**
   * Disable radio buttons if they are unavailable
   * @param {Array} newOptions array of available variants
   */
  _disableRadioButtons = newOptions => {
    const optionNotIncluded = (inputParent, input) => {
      inputParent.classList.add('opacity-50');
      input.disabled = true;
    };

    const optionIncluded = (inputParent, input) => {
      inputParent.classList.remove('opacity-50');
      input.disabled = false;
    };

    this._mutateRadioButtons(newOptions, optionNotIncluded, optionIncluded);
  };

  _updateCheckboxes = variant => {
    const radioButtons = this.el.querySelectorAll(selectors.radioButtons);

    radioButtons.forEach(radioButton => {
      radioButton.checked = false;
    });
    if (variant) {
      const selectedRadioButton = this.el.querySelector(
        `[value="${variant.option2}"]`,
      );

      if (selectedRadioButton) {
        selectedRadioButton.checked = true;
      }
    }
  };

  /**
   * Updates the hidden source of truth for the variant selected
   *
   * @param {String} selector selector of the element
   * @param {Boolean} variantFromUrl
   */
  _updateHiddenSelect = (selector, variantFromUrl) => {
    if (!variantFromUrl) {
      variantFromUrl = this.themeProductForm.variant();
    }
    const variantSource = this.el.querySelector(selector);

    if (!variantSource) {
      return;
    }
    if (variantFromUrl) {
      variantSource.value = variantFromUrl.id;
    } else {
      variantSource.value = null;
    }

    this._updateVariantState(variantFromUrl);
  };
}
