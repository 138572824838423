import cn from 'classnames';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const CartRemove = ({ item, className }) => {
  const { removeLineItem } = useCartContext();

  return (
    <button
      class={cn(className, 'type-body-xsmall text-secondary-3 underline')}
      aria-label={getTranslation('cart.remove_item')}
      type="button"
      onClick={() => removeLineItem(item.key)}
    >
      {getTranslation('cart.remove')}
    </button>
  );
};

export default CartRemove;
