import { createPortal } from 'preact/compat';
import { getTranslation } from '../../helpers';
import { useCartContext } from '../context/CartContext';
import useEmptyPortal from '../hooks/useEmptyPortal';

import { h } from 'preact';

const MiniCartEl = document.getElementById('preact-mini-cart');

const MiniCart = () => {
  const { cart, toggleMiniCart } = useCartContext();
  const [emptied, initialHtml] = useEmptyPortal(MiniCartEl);

  if (!emptied) {
    return null;
  }
  return createPortal(
    <button
      class="relative block"
      aria-label={getTranslation('header.cart')}
      onClick={() => {
        toggleMiniCart(true);
      }}
    >
      <span dangerouslySetInnerHTML={{ __html: initialHtml }} />
      {cart.item_count > 0 && (
        <div className="type-body-xsmall absolute right-0 top-0 flex h-4 w-4 items-center justify-center rounded-full bg-primary-3 text-white">
          {cart.item_count}
        </div>
      )}
    </button>,
    MiniCartEl,
  );
};

export default MiniCart;
